import React, { useEffect, useState } from 'react';

import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../util/types';
import { constructQueryParamName } from './SearchPage.shared';
import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import { Country, State, City } from 'country-state-city';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

// Helper: get enumOptions in a format that works as query parameter
const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */

const useDebounce = (value, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setDebounceValue(value);
    }, delay)

    return () => clearTimeout(timeOut);
  }, [value, delay])

  return debounceValue;
}


const FilterComponent = props => {
  const {
    idPrefix,
    config,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    marketplaceCurrency,
    intl,
    ...rest
  } = props;
  // Note: config can be either
  // - listingFields config or
  // - default filter config
  // They both have 'key' and 'schemaType' included.
  const { key, schemaType } = config;
  console.log('schemaType', config, schemaType)
  const { liveEdit, showAsPopup } = rest;
  console.log('liveEdit > > > > >', liveEdit)
  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${key.toLowerCase()}`;
  const name = key.replace(/\s+/g, '-').toLowerCase();

  const [userFilter, setUserFilter] = useState({
    country: "",
    state: "",
    city: "",
    zipCode: ""
  })
  const debounceValue = useDebounce(userFilter);

  function _setValue(e) {

    setUserFilter({ ...userFilter, [e.target.name]: e.target.value })
  }

  const format = () => {

    let KEYS = {}
    Object.keys(debounceValue)?.forEach((row) => {
      const countryParamNames = constructQueryParamName(row, null);
      KEYS = { ...KEYS, [countryParamNames]: debounceValue[row] }
    })
    let URL = ""
    if (debounceValue?.country) {
      URL += `${URL ? '&' : '?'}pub_country=${debounceValue?.country}`
    }
    if (debounceValue?.state) {
      URL += `${URL ? '&' : '?'}pub_state=${debounceValue?.state}`
    }
    if (debounceValue?.city) {
      URL += `${URL ? '&' : '?'}pub_city=${debounceValue?.city}`
    }
    if (debounceValue?.zipCode) {
      URL += `${URL ? '&' : '?'}pub_zipCode=${debounceValue?.zipCode}`
    }
    rest?.history.push(URL);
    getHandleChangedValueFn(true)
    // return KEYS;
  };


  // Default filters: price, keywords, dates
  switch (schemaType) {
    case 'price': {
      const { min, max, step } = config;
      return (
        <PriceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.priceLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={min}
          max={max}
          step={step}
          marketplaceCurrency={marketplaceCurrency}
          {...rest}
        />
      );
    }
    case 'keywords':
      return (
        <KeywordFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.keywordsLabel' })}
          name={name}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...rest}
        />
      );
    case 'dates': {
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.datesLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          minimumNights={isNightlyMode ? 1 : 0}
          {...rest}
        />
      );
    }
  }
  console.log(schemaType, 'schemaTypeschemaTypeschemaTypeschemaTypeschemaTypeschemaTypeschemaTypeschemaTypeschemaType')
  // Custom extended data filters
  switch (schemaType) {
    case SCHEMA_TYPE_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return filterConfig.filterType === 'SelectSingleFilter' ? (
        <SelectSingleFilter
          id={componentId}
          label={filterConfig.label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          options={createFilterOptions(enumOptions)}
          {...rest}
        />
      ) : (
        <SelectMultipleFilter
          id={componentId}
          label={filterConfig.label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={createFilterOptions(enumOptions)}
          schemaType={schemaType}
          {...rest}
        />
      );
    }
    case SCHEMA_TYPE_MULTI_ENUM: {
      // console.log(config, 'configconfigconfigconfigconfigconfig >        >>>>>>>>>>>>>>>>>>>>')

      // const { scope, enumOptions, filterConfig = {} } = config;
      // const { label, searchMode } = filterConfig;
      // const queryParamNames = [constructQueryParamName(key, scope)];
      // return (
      //   <SelectMultipleFilter
      //     id={componentId}
      //     label={label}
      //     name={name}
      //     queryParamNames={queryParamNames}
      //     initialValues={initialValues(queryParamNames, liveEdit)}
      //     onSubmit={getHandleChangedValueFn(useHistoryPush)}
      //     options={createFilterOptions(enumOptions)}
      //     schemaType={schemaType}
      //     searchMode={searchMode}
      //     {...rest}
      //   />
      // );
      const SELECTED_COUNTRY_CODE = _.find(Country.getAllCountries(), { name: userFilter?.country })?.isoCode;
      return (
        <div style={{
          display: 'flex',
          gap: "20px"
        }}>
          {[
            { label: 'Country', name: 'country', options: Country.getAllCountries() },
            { label: 'State', name: 'state', options: State.getStatesOfCountry(SELECTED_COUNTRY_CODE) },
            { label: 'City', name: 'city', options: City.getCitiesOfState(SELECTED_COUNTRY_CODE, (_.find(State.getStatesOfCountry(SELECTED_COUNTRY_CODE), { name: userFilter?.state })?.isoCode)) }
          ]?.map(({ label, name, options }) => (
            <div className="" style={{ flex: 1 }} key={name}>
              <select
                name={name}
                id={name}
                className={""}
                label={label}
                value={userFilter[name]}
                disabled={false}
                onChange={(e) => _setValue(e)}
              >
                <option value={''}>Select {name}</option>
                {
                  options?.map((p, i) => (
                    <option key={name + (i + 1)} value={p.name}>
                      {p.name}
                    </option>
                  ))
                }
              </select>
            </div>
          ))}
          <input
            className=''
            name='zipCode'
            style={{ flex: 1 }}
            id='zipCode'
            type="text"
            onChange={(e) => _setValue(e)}
            placeholder='zipCode'
            autoComplete="off"
          />
          <button style={{ flex: 1, cursor: 'pointer' }} onClick={() => format()}>Apply</button>
        </div>
      )
    }
    default:
      return null;
  }
};

export default withRouter(FilterComponent);
